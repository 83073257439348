import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [editingUser, setEditingUser] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(null); // Add this line
  const [userToDelete, setUserToDelete] = useState(null);

  // Fetch all users
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(`${config.BASE_URL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      alert("Failed to fetch users. Please try again.");
    }
  };

  // Edit user handler
  const handleEditUser = (user) => {
    setEditingUserId(user._id);
    setEditingUser({
      name: user.name,
      email: user.email,
      role: user.role,
      password: "",
    });
  };

  // Save user changes
  const handleSaveUser = async () => {
    if (!editingUser.name || !editingUser.email) {
      return alert("Name and email are required.");
    }

    try {
      setIsLoading(true);
      const token = localStorage.getItem("authToken");
      await axios.put(
        `${config.BASE_URL}/users/${editingUserId}`,
        { ...editingUser },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("User updated successfully!");
      setEditingUserId(null);
      fetchUsers();
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Failed to update user. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Cancel edit
  const handleCancelEdit = () => {
    setEditingUserId(null);
    setEditingUser({ name: "", email: "", role: "", password: "" });
  };

  // Delete user handler
  const handleDeleteUser = async (userId) => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.delete(`${config.BASE_URL}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert("User deleted successfully!");
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Failed to delete user. Please try again.");
    }
  };

  // Reset password handler
  const handleResetPassword = async () => {
    if (!editingUser.password || editingUser.password.length < 6) {
      return alert("Password must be at least 6 characters long.");
    }

    try {
      setIsLoading(true);
      const token = localStorage.getItem("authToken");
      await axios.put(
        `${config.BASE_URL}/users/${editingUserId}/reset-password`,
        { password: editingUser.password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Password reset successfully!");
      setEditingUserId(null);
      fetchUsers();
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("Failed to reset password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = (userId) => {
    const input = document.getElementById(`password-input-${userId}`);
    if (input) {
      input.type = input.type === "password" ? "text" : "password";
    }
  };

  // Filter users based on search term
  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      {/* Search Bar */}
      <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
        <input
          type="text"
          className="block p-2 text-sm border rounded-lg w-80 bg-gray-50 focus:ring-[#741017] focus:border-[#741017]"
          placeholder="Search for users"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* User Table */}
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th className="px-6 py-3">Name</th>
            <th className="px-6 py-3">Email</th>
            <th className="px-6 py-3">Role</th>
            <th className="px-6 py-3">Reset Password</th>
            <th className="px-6 py-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user._id} className="bg-white border-b hover:bg-gray-50">
              <td className="px-6 py-4">
                {editingUserId === user._id ? (
                  <input
                    type="text"
                    value={editingUser.name}
                    onChange={(e) =>
                      setEditingUser({ ...editingUser, name: e.target.value })
                    }
                    className="border rounded px-2 py-1"
                  />
                ) : (
                  user.name
                )}
              </td>
              <td className="px-6 py-4">
                {editingUserId === user._id ? (
                  <input
                    type="email"
                    value={editingUser.email}
                    onChange={(e) =>
                      setEditingUser({ ...editingUser, email: e.target.value })
                    }
                    className="border rounded px-2 py-1"
                  />
                ) : (
                  user.email
                )}
              </td>
              <td className="px-6 py-4">
                {editingUserId === user._id ? (
                  <select
                    value={editingUser.role}
                    onChange={(e) =>
                      setEditingUser({ ...editingUser, role: e.target.value })
                    }
                    className="border rounded px-2 py-1"
                  >
                    <option value="Admin">Admin</option>
                    <option value="Superadmin">Superadmin</option>
                    <option value="User">User</option>
                  </select>
                ) : (
                  user.role
                )}
              </td>
              <td className="px-6 py-4">
                {editingUserId === user._id && (
                  <div>
                    <input
                      id={`password-input-${user._id}`}
                      type="password"
                      placeholder="Enter new password"
                      value={editingUser.password}
                      onChange={(e) =>
                        setEditingUser({
                          ...editingUser,
                          password: e.target.value,
                        })
                      }
                      className="border rounded px-2 py-1"
                    />
                    <button
                      onClick={() => togglePasswordVisibility(user._id)}
                      className="ml-2"
                    >
                      Show
                    </button>
                  </div>
                )}
              </td>
              <td className="px-6 py-4">
                {editingUserId === user._id ? (
                  <>
                    <div className="flex space-x-2">
                      <button
                        onClick={handleSaveUser}
                        disabled={isLoading}
                        className="px-4 py-2 rounded-lg text-white bg-[#741017] hover:bg-[#5a0f12] focus:outline-none"
                      >
                        Save
                      </button>
                      <button
                        onClick={handleResetPassword}
                        disabled={isLoading}
                        className="px-4 py-2 rounded-lg text-white bg-[#741017] hover:bg-[#5a0f12] focus:outline-none"
                      >
                        Reset Password
                      </button>
                      <button
                        onClick={handleCancelEdit}
                        className="px-4 py-2 rounded-lg text-white bg-[#741017] hover:bg-[#5a0f12] focus:outline-none"
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleEditUser(user)}
                        className="px-4 py-2 rounded-lg text-white bg-[#741017] hover:bg-[#5a0f12] focus:outline-none"
                      >
                        Edit
                      </button>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => setShowDeleteModal(user._id)}
                          className="px-4 py-2 rounded-lg text-white bg-[#741017] hover:bg-[#5a0f12] focus:outline-none"
                        >
                          Delete
                        </button>

                        {/* Delete Confirmation Modal */}
                        {showDeleteModal === user._id && (
                          <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto">
                            <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6 relative">
                              {/* Close Icon */}
                              <svg
                                onClick={() => setShowDeleteModal(null)}
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-3 cursor-pointer fill-gray-400 hover:fill-red-500 float-right"
                                viewBox="0 0 320.591 320.591"
                              >
                                <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" />
                                <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" />
                              </svg>

                              {/* Modal Content */}
                              <div className="my-8 text-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-14 fill-red-500 inline"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M19 7a1 1 0 0 0-1 1v11.191A1.92 1.92 0 0 1 15.99 21H8.01A1.92 1.92 0 0 1 6 19.191V8a1 1 0 0 0-2 0v11.191A3.918 3.918 0 0 0 8.01 23h7.98A3.918 3.918 0 0 0 20 19.191V8a1 1 0 0 0-1-1Zm1-3h-4V2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM10 4V3h4v1Z" />
                                  <path d="M11 17v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Zm4 0v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Z" />
                                </svg>
                                <h4 className="text-gray-800 text-lg font-semibold mt-4">
                                  Are you sure you want to delete it?
                                </h4>
                                <p className="text-sm text-gray-600 mt-4">
                                  This action cannot be undone. The user will be
                                  permanently deleted.
                                </p>
                              </div>

                              {/* Action Buttons */}
                              <div className="flex flex-col space-y-2">
                                <button
                                  onClick={() => handleDeleteUser(user._id)}
                                  type="button"
                                  className="px-4 py-2 rounded-lg text-white text-sm bg-red-500 hover:bg-red-600"
                                >
                                  Delete
                                </button>
                                <button
                                  onClick={() => setShowDeleteModal(null)}
                                  type="button"
                                  className="px-4 py-2 rounded-lg text-gray-800 text-sm bg-gray-200 hover:bg-gray-300"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagement;
