import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaDownload } from "react-icons/fa"; // For download icons
import { jsPDF } from "jspdf"; // For generating PDF
import config from "../../config";
import "jspdf-autotable"; // Import the autoTable plugin

const CRUDTable = () => {
  const [vendors, setVendors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [vendorsPerPage] = useState(10);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [editVendorId, setEditVendorId] = useState(null); // Vendor in edit mode
  const [editableFields, setEditableFields] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch all vendor details
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/vendors`);
        console.log("Fetched Vendors:", response.data); // Debug: Log fetched data
        setVendors(response.data);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

    fetchVendors();
  }, []);

  const toggleDropdown = (vendorID) => {
    setActiveDropdown(activeDropdown === vendorID ? null : vendorID);
  };

  const handleEdit = (vendor) => {
    setEditVendorId(vendor._id);
    setEditableFields({
      // Basic Details
      name: vendor.name,
      phone_extension: vendor.phone_extension,
      phone: vendor.phone,
      email: vendor.email,
      vendor_type: vendor.vendor_type,

      // Local Vendor Specific Details
      local_vendor_name: vendor.local_vendor_name,
      customer_account: vendor.customer_account,
      short_address: vendor.short_address,
      building_no: vendor.building_no,
      street_secondary_number: vendor.street_secondary_number,
      district: vendor.district,

      // Address Details
      address_line_1: vendor.address_line_1,
      address_line_2: vendor.address_line_2,
      city: vendor.city,
      state: vendor.state,
      postcode: vendor.postcode,
      country: vendor.country,

      // Contact Details
      website: vendor.website,
      fax_extension: vendor.fax_extension,
      fax: vendor.fax,

      // Registration Details
      contactable_person_name: vendor.contactable_person_name,
      register_email: vendor.register_email,
      cr_number: vendor.cr_number,
      supplier_type: vendor.supplier_type,
      classification_type: vendor.classification_type,
      taxpayer_id: vendor.taxpayer_id,
      tax_country: vendor.tax_country,
      vat_number: vendor.vat_number,

      // Bank Details
      bank_name: vendor.bank_name,
      account_name: vendor.account_name,
      account_number: vendor.account_number,
      branch: vendor.branch,
      iban: vendor.iban,
      currency: vendor.currency,
    });
  };

  const handleSave = async (vendorId) => {
    try {
      // Create a copy of the editableFields state
      const updatedFields = { ...editableFields };

      // Remove empty or undefined fields from the update payload
      Object.keys(updatedFields).forEach((key) => {
        if (
          updatedFields[key] === "" ||
          updatedFields[key] === null ||
          updatedFields[key] === undefined
        ) {
          delete updatedFields[key];
        }
      });

      // Send the filtered fields to the backend
      await axios.put(`${config.BASE_URL}/vendors/${vendorId}`, updatedFields);

      // Refetch the updated vendor list
      const response = await axios.get(`${config.BASE_URL}/vendors`);
      setVendors(response.data); // Update the vendor list in the state

      setEditVendorId(null);
      alert("Vendor updated successfully.");
    } catch (error) {
      console.error(
        "Error saving vendor:",
        error.response ? error.response.data : error.message
      );
      alert(
        `Failed to save vendor: ${
          error.response?.data?.error || "Network/server error"
        }`
      );
    }
  };

  const handleDelete = async (vendorId) => {
    if (window.confirm("Are you sure you want to delete this vendor?")) {
      try {
        await axios.delete(`${config.BASE_URL}/vendors/${vendorId}`);
        setVendors(vendors.filter((vendor) => vendor._id !== vendorId));
        alert("Vendor deleted successfully.");
      } catch (error) {
        console.error("Error deleting vendor:", error);
        alert("Failed to delete vendor.");
      }
    }
  };

  const handlePreview = (vendor) => {
    const doc = new jsPDF();

    // Title
    doc.setFontSize(16);
    doc.text("Vendor Details Preview", 10, 10);

    // Exclude unwanted fields (certificates and start/end dates)
    const excludedFields = [
      "vat_certificate",
      "coc_certificate",
      "zakat_certificate",
      "iban_certificate",
      "national_address_certificate",
      "company_profile",
      "pre_qualification",
      "vat_certificate_start",
      "vat_certificate_end",
      "coc_certificate_start",
      "coc_certificate_end",
      "zakat_certificate_start",
      "zakat_certificate_end",
      "iban_certificate_start",
      "iban_certificate_end",
      "national_address_start",
      "national_address_end",
    ];

    const rows = [];
    Object.keys(vendor).forEach((key) => {
      if (!excludedFields.includes(key)) {
        rows.push([key.replace(/_/g, " ").toUpperCase(), vendor[key] || "N/A"]);
      }
    });

    // Generate the table with two columns: Field and Value, each taking 50% width
    doc.autoTable({
      head: [["Field", "Value"]], // Table headers
      body: rows, // Table data
      startY: 20, // Position below the title
      styles: {
        fontSize: 10,
        cellPadding: 3,
      },
      columnStyles: {
        0: { cellWidth: 95 }, // Field column (50% of page width)
        1: { cellWidth: 95 }, // Value column (50% of page width)
      },
      margin: { top: 10 },
      didDrawPage: (data) => {
        // Add page number at the bottom of each page
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        doc.text(
          `Page ${pageCount}`,
          data.settings.margin.left,
          doc.internal.pageSize.height - 10
        );
      },
    });

    // Save or open the PDF
    doc.save(`Vendor_${vendor.vendorID}.pdf`);
  };

  const filteredVendors = vendors.filter((vendor) =>
    ["vendor_type", "name", "supplier_type", "classification_type"].some(
      (key) =>
        (vendor[key] || "").toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const indexOfLastVendor = currentPage * vendorsPerPage;
  const indexOfFirstVendor = indexOfLastVendor - vendorsPerPage;
  const currentVendors = filteredVendors.slice(
    indexOfFirstVendor,
    indexOfLastVendor
  );

  const totalPages = Math.ceil(filteredVendors.length / vendorsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

const validateFileDownload = async (e, fileName) => {
  e.preventDefault(); // Prevent default anchor behavior

  try {
    const response = await axios.get(`${config.BASE_URL}/api/vendors/download/${fileName}`, {
      responseType: "blob", // Ensures the file is downloaded as a Blob
    });

    // Create a URL for the downloaded Blob and trigger a download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // Set download file name
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    alert("The requested file is not available.");
  }
};


  return (
    <section className="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            {/* Search Bar */}
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2"
                    placeholder="Search Vendor ID, Name, Supplier Type, Classification Type"
                    value={searchTerm} // Bind the search input value
                    onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
                  />
                </div>
              </form>
            </div>
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              {/*
<button
  type="button"
  className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
>
  <svg
    className="h-3.5 w-3.5 mr-2"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
    />
  </svg>
  Add Vendor
</button>
*/}

              {/*<div className="flex items-center space-x-3 w-full md:w-auto">
                <button id="actionsDropdownButton" data-dropdown-toggle="actionsDropdown" className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                  <svg className="-ml-1 mr-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                  </svg>
                  Actions
                </button>
                <div id="actionsDropdown" className="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                  <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="actionsDropdownButton">
                    <li>
                      <a href="#" className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Mass Edit</a>
                    </li>
                  </ul>
                  <div className="py-1">
                    <a href="#" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete All</a>
                  </div>
                </div>
              </div>
              <button id="filterDropdownButton" data-dropdown-toggle="filterDropdown" className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="h-4 w-4 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
                </svg>
                Filter
                <svg className="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clipRule="evenodd" fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                </svg>
              </button>
              <div id="filterDropdown" className="z-10 hidden w-48 p-3 bg-white rounded-lg shadow dark:bg-gray-700">
                <h6 className="mb-3 text-sm font-medium text-gray-900 dark:text-white">Choose brand</h6>
                <ul className="space-y-2 text-sm" aria-labelledby="filterDropdownButton">
                  <li className="flex items-center">
                    <input id="apple" type="checkbox" value="" className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                    <label htmlFor="apple" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">Apple (56)</label>
                  </li>
                  <li className="flex items-center">
                    <input id="fitbit" type="checkbox" value="" className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                    <label htmlFor="fitbit" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">Microsoft (16)</label>
                  </li>
                  <li className="flex items-center">
                    <input id="razor" type="checkbox" value="" className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                    <label htmlFor="razor" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">Razor (49)</label>
                  </li>
                  <li className="flex items-center">
                    <input id="nikon" type="checkbox" value="" className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                    <label htmlFor="nikon" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">Nikon (12)</label>
                  </li>
                  <li className="flex items-center">
                    <input id="benq" type="checkbox" value="" className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                    <label htmlFor="benq" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">BenQ (74)</label>
                  </li>
                </ul>
              </div>*/}
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Vendor ID
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Phone Extension
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Phone
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Vendor Type
                  </th>{" "}
                  {/* Moved Vendor Type after Email */}
                  <th scope="col" className="px-4 py-3">
                    Local Vendor Name
                  </th>{" "}
                  {/* New field for Local Vendor */}
                  <th scope="col" className="px-4 py-3">
                    Customer Account
                  </th>{" "}
                  {/* New field for Local Vendor */}
                  <th scope="col" className="px-4 py-3">
                    Short Address
                  </th>{" "}
                  {/* New field for Local Vendor */}
                  <th scope="col" className="px-4 py-3">
                    Building Number
                  </th>{" "}
                  {/* New field for Local Vendor */}
                  <th scope="col" className="px-4 py-3">
                    Street Secondary Number
                  </th>{" "}
                  {/* New field for Local Vendor */}
                  <th scope="col" className="px-4 py-3">
                    District
                  </th>{" "}
                  {/* New field for Local Vendor */}
                  <th scope="col" className="px-4 py-3">
                    Address Line 1
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Address Line 2
                  </th>
                  <th scope="col" className="px-4 py-3">
                    City
                  </th>
                  <th scope="col" className="px-4 py-3">
                    State
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Postcode
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Country
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Website
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Fax Extension
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Fax
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Registerer Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Registerer Email
                  </th>
                  <th scope="col" className="px-4 py-3">
                    CR Number
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Supplier Type
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Classification Type
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Taxpayer ID
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Tax Country
                  </th>
                  <th scope="col" className="px-4 py-3">
                    VAT Number
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Bank Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Account Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Account Number
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Branch
                  </th>
                  <th scope="col" className="px-4 py-3">
                    IBAN
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Currency
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Certificates
                  </th>{" "}
                  {/* Certificates dropdown */}
                  <th scope="col" className="px-4 py-3">
                    Update
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentVendors.map((vendor) => (
                  <tr
                    key={vendor._id}
                    className="border-b dark:border-gray-700 dark:bg-gray-800"
                  >
                    <td className="px-4 py-4">{vendor.vendorID}</td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.name
                            : vendor.name
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            name: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.phone_extension
                            : vendor.phone_extension
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            phone_extension: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.phone
                            : vendor.phone
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            phone: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.email
                            : vendor.email
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            email: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.vendor_type
                            : vendor.vendor_type
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            vendor_type: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    {/* Local Vendor Fields */}
                    {vendor.vendor_type === "local" && (
                      <>
                        <td className="px-4 py-4">
                          <input
                            type="text"
                            value={
                              editVendorId === vendor._id
                                ? editableFields.local_vendor_name
                                : vendor.local_vendor_name
                            }
                            onChange={(e) =>
                              setEditableFields({
                                ...editableFields,
                                local_vendor_name: e.target.value,
                              })
                            }
                            className="border rounded p-1"
                            readOnly={editVendorId !== vendor._id}
                          />
                        </td>
                        <td className="px-4 py-4">
                          <input
                            type="text"
                            value={
                              editVendorId === vendor._id
                                ? editableFields.customer_account
                                : vendor.customer_account
                            }
                            onChange={(e) =>
                              setEditableFields({
                                ...editableFields,
                                customer_account: e.target.value,
                              })
                            }
                            className="border rounded p-1"
                            readOnly={editVendorId !== vendor._id}
                          />
                        </td>
                        <td className="px-4 py-4">
                          <input
                            type="text"
                            value={
                              editVendorId === vendor._id
                                ? editableFields.short_address
                                : vendor.short_address
                            }
                            onChange={(e) =>
                              setEditableFields({
                                ...editableFields,
                                short_address: e.target.value,
                              })
                            }
                            className="border rounded p-1"
                            readOnly={editVendorId !== vendor._id}
                          />
                        </td>
                        <td className="px-4 py-4">
                          <input
                            type="text"
                            value={
                              editVendorId === vendor._id
                                ? editableFields.building_no
                                : vendor.building_no
                            }
                            onChange={(e) =>
                              setEditableFields({
                                ...editableFields,
                                building_no: e.target.value,
                              })
                            }
                            className="border rounded p-1"
                            readOnly={editVendorId !== vendor._id}
                          />
                        </td>
                        <td className="px-4 py-4">
                          <input
                            type="text"
                            value={
                              editVendorId === vendor._id
                                ? editableFields.street_secondary_number
                                : vendor.street_secondary_number
                            }
                            onChange={(e) =>
                              setEditableFields({
                                ...editableFields,
                                street_secondary_number: e.target.value,
                              })
                            }
                            className="border rounded p-1"
                            readOnly={editVendorId !== vendor._id}
                          />
                        </td>
                        <td className="px-4 py-4">
                          <input
                            type="text"
                            value={
                              editVendorId === vendor._id
                                ? editableFields.district
                                : vendor.district
                            }
                            onChange={(e) =>
                              setEditableFields({
                                ...editableFields,
                                district: e.target.value,
                              })
                            }
                            className="border rounded p-1"
                            readOnly={editVendorId !== vendor._id}
                          />
                        </td>
                      </>
                    )}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.address_line_1
                            : vendor.address_line_1
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            address_line_1: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.address_line_2
                            : vendor.address_line_2
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            address_line_2: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.city
                            : vendor.city
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            city: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.state
                            : vendor.state
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            state: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.postcode
                            : vendor.postcode
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            postcode: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.country
                            : vendor.country
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            country: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.website
                            : vendor.website
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            website: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.fax_extension
                            : vendor.fax_extension
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            fax_extension: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    {/* Fax */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.fax
                            : vendor.fax
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            fax: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* Register Name */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.contactable_person_name
                            : vendor.contactable_person_name
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            contactable_person_name: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* Register Email */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.register_email
                            : vendor.register_email
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            register_email: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.cr_number
                            : vendor.cr_number
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            cr_number: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>
                    {/* Supplier Type */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.supplier_type
                            : vendor.supplier_type
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            supplier_type: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* Classification Type */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.classification_type
                            : vendor.classification_type
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            classification_type: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.taxpayer_id
                            : vendor.taxpayer_id
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            taxpayer_id: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* Tax Country */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.tax_country
                            : vendor.tax_country
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            tax_country: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* VAT Number */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.vat_number
                            : vendor.vat_number
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            vat_number: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* Bank Name */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.bank_name
                            : vendor.bank_name
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            bank_name: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* Account Name */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.account_name
                            : vendor.account_name
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            account_name: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* Account Number */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.account_number
                            : vendor.account_number
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            account_number: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* Branch */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.branch
                            : vendor.branch
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            branch: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* IBAN */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.iban
                            : vendor.iban
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            iban: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* Currency */}
                    <td className="px-4 py-4">
                      <input
                        type="text"
                        value={
                          editVendorId === vendor._id
                            ? editableFields.currency
                            : vendor.currency
                        }
                        onChange={(e) =>
                          setEditableFields({
                            ...editableFields,
                            currency: e.target.value,
                          })
                        }
                        className="border rounded p-1"
                        readOnly={editVendorId !== vendor._id}
                      />
                    </td>

                    {/* Certificates */}
                    <td className="px-4 py-4">
                      <div className="relative inline-block text-left">
                        <button
                          onClick={() => toggleDropdown(vendor._id)}
                          className="text-white bg-[#741017] hover:bg-[#5a0f12] focus:ring-4 focus:outline-none focus:ring-[#5a0f12] font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                        >
                          Certificates
                          <svg
                            className="w-2.5 h-2.5 ml-2"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>

                        {activeDropdown === vendor._id && (
                          <div
                            className="z-10 absolute right-0 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            <div className="py-1" role="none">
                            <div className="py-1" role="none">
    {/* VAT Certificate */}
    {vendor.vat_certificate && (
      <a
        href={`${config.BASE_URL}/vendors/download/${vendor.vat_certificate}`}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        VAT Certificate
      </a>
    )}
    {/* COC Certificate */}
    {vendor.coc_certificate && (
      <a
        href={`${config.BASE_URL}/vendors/download/${vendor.coc_certificate}`}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        COC Certificate
      </a>
    )}
    {/* Zakat Certificate */}
    {vendor.zakat_certificate && (
      <a
        href={`${config.BASE_URL}/vendors/download/${vendor.zakat_certificate}`}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Zakat Certificate
      </a>
    )}
    {/* IBAN Certificate */}
    {vendor.iban_certificate && (
      <a
        href={`${config.BASE_URL}/vendors/download/${vendor.iban_certificate}`}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        IBAN Certificate
      </a>
    )}
    {/* National Address Certificate */}
    {vendor.national_address_certificate && (
      <a
        href={`${config.BASE_URL}/vendors/download/${vendor.national_address_certificate}`}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        National Address Certificate
      </a>
    )}
    {/* Company Profile */}
    {vendor.company_profile && (
      <a
        href={`${config.BASE_URL}/vendors/download/${vendor.company_profile}`}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Company Profile
      </a>
    )}
    {/* Pre-Qualification Certificate */}
    {vendor.pre_qualification && (
      <a
        href={`${config.BASE_URL}/vendors/download/${vendor.pre_qualification}`}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Pre-Qualification Certificate
      </a>
    )}
</div>

                            </div>
                          </div>
                        )}
                      </div>
                    </td>

                    {/* Actions */}
                    <td className="px-4 py-3 flex items-center justify-end">
                      {localStorage.getItem("userRole") === "admin" ||
                      localStorage.getItem("userRole") === "superadmin" ? (
                        editVendorId === vendor._id ? (
                          <>
                            {/* Save Button */}
                            <button
                              onClick={() => {
                                handleSave(vendor._id, editableFields); // Save the edited fields
                              }}
                              className="py-2 px-4 text-green-500 hover:bg-gray-100"
                            >
                              Save
                            </button>
                            <span className="mx-2">|</span>
                            {/* Cancel Button */}
                            <button
                              onClick={() => {
                                setEditVendorId(null); // Exit edit mode without saving
                                setEditableFields({}); // Clear editable fields
                              }}
                              className="py-2 px-4 text-gray-500 hover:bg-gray-100"
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <>
                            {/* Edit Button */}
                            <button
                              onClick={() => {
                                handleEdit(vendor); // Enter edit mode and populate fields
                              }}
                              className="py-2 px-4 text-blue-500 hover:bg-gray-100"
                            >
                              Edit
                            </button>
                            <span className="mx-2">|</span>
                            {/* Delete Button */}
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this vendor?"
                                  )
                                ) {
                                  handleDelete(vendor._id); // Confirm and delete the vendor
                                }
                              }}
                              className="py-2 px-4 text-red-500 hover:bg-gray-100"
                            >
                              Delete
                            </button>
                            <span className="mx-2">|</span>
                            {/* Preview Button */}
                            <button
                              onClick={() => {
                                handlePreview(vendor); // Open a preview modal or page
                              }}
                              className="py-2 px-4 text-purple-500 hover:bg-gray-100"
                            >
                              Preview
                            </button>
                          </>
                        )
                      ) : (
                        <p className="text-gray-400">Restricted</p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <nav
            className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
              Showing
              <span className="font-semibold text-gray-900 dark:text-white">
                {indexOfFirstVendor + 1}-
                {indexOfLastVendor > vendors.length
                  ? vendors.length
                  : indexOfLastVendor}
              </span>
              of
              <span className="font-semibold text-gray-900 dark:text-white">
                {vendors.length}
              </span>
            </span>
            <ul className="inline-flex items-stretch -space-x-px">
              <li>
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index + 1}>
                  <button
                    onClick={() => paginate(index + 1)}
                    className={`flex items-center justify-center text-sm py-2 px-3 leading-tight ${
                      currentPage === index + 1
                        ? "text-primary-600 bg-primary-50 border border-primary-300"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    } dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default CRUDTable;
