import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png'; // Adjust the path based on your directory structure

const Sidebar = () => {
  const userRole = localStorage.getItem('userRole'); // Get the user role from localStorage
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown open/close

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div 
      className="fixed top-0 left-0 h-full w-56 flex flex-col p-4 shadow-xl" 
      style={{ backgroundColor: '#f5f5f5' }} // Sidebar background color
    >
      {/* Logo */}
      <div className="p-4 mb-2 flex justify-center items-center">
        <img 
          src={logo} // Use the imported logo here
          alt="Company Logo"
          className="h-25 w-auto" // Adjust height and width as needed
        />
      </div>

      <nav className="flex flex-col gap-1 p-2 text-base font-normal">
        
        {/* Home Link */}
        <div 
          role="button" 
          className="flex items-center w-full p-3 transition-all rounded-lg hover:bg-gray-200"
        >
          <div className="grid mr-4 place-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#741017" className="w-5 h-5">
              <path fillRule="evenodd" d="M2.25 2.25a.75.75 0 000 1.5H3v10.5a3 3 0 003 3h1.21l-1.172 3.513a.75.75 0 001.424.474l.329-.987h8.418l.33.987a.75.75 0 001.422-.474l-1.17-3.513H18a3 3 0 003-3V3.75h.75a.75.75 0 000-1.5H2.25z" clipRule="evenodd" />
            </svg>
          </div>
          <Link to="/dashboard" style={{ color: '#741017' }}>Dashboard</Link>
        </div>

        {/* Manage Vendors */}
        <div 
          role="button" 
          className="flex items-center w-full p-3 transition-all rounded-lg hover:bg-gray-200"
        >
          <div className="grid mr-4 place-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#741017" className="w-5 h-5">
              <path fillRule="evenodd" d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 004.25 22.5h15.5a1.875 1.875 0 001.865-2.071l-1.263-12a1.875 1.875 0 00-1.865-1.679H16.5V6a4.5 4.5 0 10-9 0zM12 3a3 3 0 00-3 3v.75h6V6a3 3 0 00-3-3zm-3 8.25a3 3 0 106 0v-.75a.75.75 0 011.5 0v.75a4.5 4.5 0 11-9 0v-.75a.75.75 0 011.5 0v.75z" clipRule="evenodd" />
            </svg>
          </div>
          <Link to="/manage-vendors" style={{ color: '#741017' }}>Manage Vendors</Link>
        </div>

        {/* Reports */}
        <div 
          role="button" 
          className="flex items-center w-full p-3 transition-all rounded-lg hover:bg-gray-200"
        >
          <div className="grid mr-4 place-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#741017" className="w-5 h-5">
              <path fillRule="evenodd" d="M6.912 3a3 3 0 00-2.868 2.118l-2.411 7.838a3 3 0 00-.133.882V18a3 3 0 003 3h15a3 3 0 003-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0017.088 3H6.912zm13.823 9.75l-2.213-7.191A1.5 1.5 0 0017.088 4.5H6.912a1.5 1.5 0 00-1.434 1.059L3.265 12.75H6.11a3 3 0 012.684 1.658l.256.513a1.5 1.5 0 001.342.829h3.218a1.5 1.5 0 001.342-.83l.256-.512a3 3 0 012.684-1.658h2.844z" clipRule="evenodd" />
            </svg>
          </div>
          <Link to="/reports" style={{ color: '#741017' }}>Reports</Link>
        </div>

        {/* Users (Dropdown) - Visible only to Superadmin */}
        {userRole === 'superadmin' && (
          <>
            <div 
              role="button" 
              onClick={toggleDropdown} 
              className="flex items-center w-full p-3 transition-all rounded-lg hover:bg-gray-200"
            >
              <div className="grid mr-4 place-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className={`w-5 h-5 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} viewBox="0 0 24 24" fill="#741017">
                  <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 011.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.21 8.27a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                </svg>
              </div>
              <span style={{ color: '#741017' }}>Users</span>
            </div>

            {isDropdownOpen && (
              <ul className="pl-4 mt-2 space-y-2">
                <li>
                  <Link to="/user-management" className="block py-2 px-4 hover:bg-gray-200 rounded-lg" style={{ color: '#741017' }}>
                    User Management
                  </Link>
                </li>
                <li>
                  <Link to="/create-user" className="block py-2 px-4 hover:bg-gray-200 rounded-lg" style={{ color: '#741017' }}>
                    Create User
                  </Link>
                </li>
              </ul>
            )}
          </>
        )}
      </nav>
    </div>
  );
};

export default Sidebar;
