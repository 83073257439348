import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#6b6a6a] text-white py-4 mt-12">
      <div className="container mx-auto text-center px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
        <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">
          &copy; {new Date().getFullYear()} Shar Company. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
