import React, { useState, useEffect } from "react";
import axios from "axios";

const PrivacyPolicy = ({ companyName }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const policyStatus = localStorage.getItem("privacyPolicyStatus");
    if (!policyStatus) {
      setShowModal(true);
    }
  }, []);

  const logAction = async (action) => {
    try {
      const domainName = window.location.hostname;
      await axios.post(`${process.env.REACT_APP_BASE_URL}/privacy-policy/log`, {
        action,
        domainName,
      });
    } catch (error) {
      console.error("Error logging privacy policy action:", error);
    }
  };

  const handleAction = (action) => {
    const timestamp = new Date().toISOString();
    localStorage.setItem(
      "privacyPolicyStatus",
      JSON.stringify({ action, timestamp })
    );
    logAction(action);
    setShowModal(false);
  };

  if (!showModal) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative p-4 w-full max-w-2xl">
        <div className="bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex justify-between items-center p-4 border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Privacy Policy
            </h3>
            <button
              className="w-8 h-8 text-gray-400 rounded-lg hover:text-gray-900 hover:bg-gray-200"
              onClick={() => setShowModal(false)}
            >
              <svg className="w-3 h-3" fill="none" viewBox="0 0 14 14">
                <path
                  d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 space-y-4 text-gray-500 dark:text-gray-400 max-h-96 overflow-y-auto">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
              Shar Company - Supplier Portal Terms and Conditions
            </h2>
            <p>
              <strong>1. Introduction</strong>
              <br />
              At Shar Company, we recognize the crucial role our vendors play in
              our mission to deliver exceptional services. Strong partnerships
              with vendors are essential to meet client expectations and ensure
              mutual success. By accessing Shar Company's Supplier Portal, you
              agree to comply with these terms and conditions, as well as all
              applicable laws and regulations.
            </p>

            <p>
              <strong>2. Supplier Obligations</strong>
              <br />
              By logging into Shar Company's Supplier Portal, you agree to the
              following:
              <br />
              • Your company must be at least 4 years old to register as a
              supplier.
              <br />
              • Your company, its representatives, and individuals will comply
              with Shar Company's Supplier Code of Conduct, policies, applicable
              laws, and regulations. You agree to act ethically and avoid
              conflicts of interest.
              <br />
              • Your company will avoid any interaction with Shar Company
              employees that could create a real or perceived conflict of
              interest.
              <br />• Your company, its representatives, and individuals must
              not engage in any unethical or illegal activities, such as bribery
              or kickbacks.
            </p>

            <p>
              <strong>3. Supplier Documentation Requirements</strong>
              <br />
              To register as a supplier with Shar Company, you must provide the
              following documents:
              <br />
              • Commercial Registration or Trade License
              <br />
              • National Address
              <br />
              • Zakat Certificate (for KSA suppliers)
              <br />
              • VAT Certificate
              <br />• Bank details
            </p>

            <p>
              <strong>4. Privacy and Security</strong>
              <br />
              Shar Company is committed to protecting the privacy and security
              of its suppliers. Your personal and company information will only
              be collected with your consent. We use advanced encryption to
              safeguard data and comply with legal obligations under Saudi law.
              Cookies may be used to enhance your experience on the portal.
              These cookies store data like browser type, IP address, and access
              times, and help improve user interactions. Refusal to accept
              cookies may affect the website's functionality but will not
              prevent access to the portal.
            </p>

            <p>
              <strong>5. Prohibited Activities</strong>
              <br />
              The use of Shar Company's Supplier Portal is limited to lawful
              activities. You agree not to:
              <br />
              • Use the portal for illegal purposes or to commit crimes.
              <br />
              • Send harassing, threatening, or offensive communications,
              including those based on race, religion, or gender.
              <br />
              • Tamper with the website’s security features, decrypt secured
              information, or attempt unauthorized access to any part of the
              website.
              <br />• Install malware, viruses, or other harmful content on the
              portal.
            </p>

            <p>
              <strong>6. Legal Liability</strong>
              <br />
              Shar Company is not liable for:
              <br />
              • Any technical malfunctions, incomplete transmissions, or delays.
              <br />
              • Loss of information or data during any transactions.
              <br />
              • Unavailability of network connections or disruptions to service.
              <br />
              Shar Company assumes no liability for any damages, direct or
              indirect, that may arise from the use of the portal or its
              content. We reserve the right to revise these terms at any time,
              and the updated version will be posted on the website.
            </p>

            <p>
              <strong>7. Termination of Access</strong>
              <br />
              Shar Company reserves the right to terminate access to the
              Supplier Portal for any user who violates these terms or engages
              in unauthorized activities.
            </p>

            <p>
              <strong>8. Privacy Protection and Data Collection</strong>
              <br />
              Shar Company is dedicated to ensuring the protection of all
              personal information provided through the Supplier Portal. We only
              collect data with user consent and ensure that it is kept
              confidential, except where required by law.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mt-6">
              شروط وأحكام بوابة الموردين شركة شار
            </h2>
            <p>
              <strong>1-المقدمة</strong>
              <br />
              تدرك شركة شار أهمية الدور الحيوي الذي يقوم به الموردون في تحقيق
              رؤيتنا لتقديم خدمات استثنائية. إذ تُعد الشراكات القوية مع الموردين
              أساسية لتلبية توقعات العملاء وضمان النجاح المشترك. من خلال الوصول
              إلى بوابة الموردين التابعة لشركة شار، فإنك توافق على الامتثال لهذه
              الشروط والأحكام، بالإضافة إلى جميع القوانين واللوائح المعمول بها.
            </p>

            <p>
              <strong>2-التزامات الموردين:</strong>
              <br />
              عند تسجيل الدخول إلى بوابة الموردين لشركة شار، فإنك توافق على ما
              يلي:
              <br />
              • يجب أن تكون شركتك قد تأسست منذ 4 سنوات على الأقل لتتمكن من
              التسجيل كمورد.
              <br />
              • تلتزم شركتك وممثلوها وأفرادها بقواعد سلوك الموردين لشركة شار
              والسياسات والقوانين واللوائح المعمول بها، والالتزام بالتصرف
              بأخلاقيات عالية وتجنب تضارب المصالح.
              <br />
              • أن تتجنب شركتك أي تفاعل مع موظفي شركة شار من شأنه أن يخلق
              تضاربًا حقيقيًا أو متصورًا في المصالح.
              <br />• يجب ألا تشارك شركتك وممثلوها وأفرادها في أي أنشطة غير
              أخلاقية أو غير قانونية، مثل الرشوة أو العمولات أو أي نشاط مشبوه.
            </p>

            <p>
              <strong>3-متطلبات توثيق الموردين:</strong>
              <br />
              للتسجيل كمورد لدى شركة شار، يجب عليك تقديم المستندات التالية:
              <br />
              • السجل التجاري
              <br />
              • العنوان الوطني
              <br />
              • شهادة الزكاة (للموردين في المملكة العربية السعودية)
              <br />
              • شهادة ضريبة القيمة المضافة
              <br />• بيانات الحساب البنكي
            </p>

            <p>
              <strong>4-الخصوصية والأمان:</strong>
              <br />
              تلتزم شركة شار بحماية خصوصية وأمان الموردين. لن يتم جمع معلوماتك
              الشخصية أو معلومات شركتك إلا بموافقتك. نحن نستخدم تقنيات تشفير
              متقدمة لحماية البيانات ونلتزم بالالتزامات القانونية وفقًا لقوانين
              المملكة العربية السعودية. قد يتم استخدام ملفات تعريف الارتباط
              (الكوكيز) لتحسين تجربتك على البوابة. حيث تقوم هذه الملفات بتخزين
              بيانات مثل نوع المتصفح، عنوان الـ IP، وأوقات الوصول، مما يساعد في
              تحسين التفاعل مع المستخدم. قد يؤثر رفض قبول ملفات تعريف الارتباط
              على وظائف الموقع، ولكنه لن يمنع الوصول إلى البوابة.
            </p>

            <p>
              <strong>5-الأنشطة المحظورة:</strong>
              <br />
              يقتصر استخدام بوابة الموردين التابعة لشركة شار على الأنشطة
              القانونية فقط. وتوافق على الامتناع عن:
              <br />
              • استخدام البوابة لأغراض غير قانونية أو لارتكاب جرائم.
              <br />
              • إرسال رسائل تحرش أو تهديد أو إساءة، بما في ذلك تلك التي تستند
              إلى العرق أو الدين أو الجنس.
              <br />
              • العبث بميزات الأمان الخاصة بالموقع أو فك تشفير المعلومات المحمية
              أو محاولة الوصول غير المصرح به إلى أي جزء من الموقع.
              <br />• تثبيت برامج ضارة أو فيروسات أو أي محتوى ضار على البوابة.
            </p>

            <p>
              <strong>6-المسؤولية القانونية:</strong>
              <br />
              لا تتحمل شركة شار المسؤولية عن:
              <br />
              • أي أعطال تقنية أو عمليات نقل غير مكتملة أو تأخيرات.
              <br />
              • فقدان المعلومات أو البيانات أثناء أي معاملات.
              <br />
              • عدم توفر الاتصال بالشبكة أو انقطاع الخدمة.
              <br />
              لا تتحمل شركة شار أي مسؤولية عن أي أضرار مباشرة أو غير مباشرة قد
              تنشأ عن استخدام البوابة أو محتواها. كما نحتفظ بالحق في تعديل هذه
              الشروط في أي وقت، وسيتم نشر النسخة المحدثة على الموقع.
            </p>

            <p>
              <strong>7-حماية الخصوصية وجمع البيانات:</strong>
              <br />
              تلتزم شركة شار بضمان حماية جميع المعلومات الشخصية المقدمة من خلال
              بوابة الموردين. لا يتم جمع البيانات إلا بموافقة المستخدم ونضمن
              الحفاظ على سريتها، باستثناء الحالات التي يقتضيها القانون.
            </p>
          </div>
          <div className="flex items-center p-4 border-t dark:border-gray-600">
            <button
              className="px-5 py-2.5 text-sm font-medium text-white bg-[#741017] rounded-lg hover:bg-gray-600"
              onClick={() => handleAction("Accepted")}
            >
              I accept
            </button>
            <button
              className="px-5 py-2.5 ml-3 text-sm font-medium text-gray-900 bg-white border rounded-lg hover:bg-gray-100 hover:text-[#741017] dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400"
              onClick={() => handleAction("Declined")}
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
