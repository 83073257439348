import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  format,
  parseISO,
  isValid,
  differenceInDays,
  formatDistanceToNow,
} from "date-fns";
import Papa from "papaparse";
import config from "../../config"; // Import config for BASE_URL

const ManageVendor = () => {
  const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [totalVendors, setTotalVendors] = useState(0);
  const [vendorsThisMonth, setVendorsThisMonth] = useState(0);
  const [supplierTypes, setSupplierTypes] = useState([]);
  const [classificationTypes, setClassificationTypes] = useState([]);
  const [supplierTypeCounts, setSupplierTypeCounts] = useState({});
  const [classificationTypeCounts, setClassificationTypeCounts] = useState({});
  const [newSupplierType, setNewSupplierType] = useState("");
  const [newClassificationType, setNewClassificationType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showAllSupplierTypes, setShowAllSupplierTypes] = useState(false);
  const [showAllClassificationTypes, setShowAllClassificationTypes] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const vendorsPerPage = 5;
  const maxItemsToShow = 4;
  const [userRole, setUserRole] = useState(""); // State for user role

  useEffect(() => {
    const role = localStorage.getItem("userRole");
    if (role) {
      setUserRole(role); // Set user role from localStorage
    }

    const fetchVendors = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/vendors`);
        const vendorsData = response.data;
        setVendors(vendorsData);
        setFilteredVendors(vendorsData);
        calculateVendorStats(vendorsData);
        calculateTypeCounts(vendorsData);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

    const fetchTypes = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/types`);
        const typesData = response.data?.data || [];

        if (!Array.isArray(typesData)) {
          console.error("Error: typesData is not an array.");
          return;
        }

        const suppliers = typesData
          .filter((type) => type.supplier_type)
          .map((type) => ({
            id: type._id,
            name: type.supplier_type,
            createdAt: type.createdAt,
          }));

        const classifications = typesData
          .filter((type) => type.classification_type)
          .map((type) => ({
            id: type._id,
            name: type.classification_type,
            createdAt: type.createdAt,
          }));

        setSupplierTypes(suppliers);
        setClassificationTypes(classifications);
      } catch (error) {
        console.error("Error fetching types:", error);
      }
    };
    fetchVendors();
    fetchTypes();
  }, []);

  const calculateVendorStats = (vendors) => {
    const total = vendors.length;
    const thisMonth = vendors.filter((vendor) => {
      const vendorDate = new Date(vendor.createdAt);
      const currentMonth = new Date().getMonth();
      return vendorDate.getMonth() === currentMonth;
    }).length;

    setTotalVendors(total);
    setVendorsThisMonth(thisMonth);
  };

  const calculateTypeCounts = (vendors) => {
    const supplierCounts = {};
    const classificationCounts = {};

    vendors.forEach((vendor) => {
      if (vendor.supplier_type) {
        supplierCounts[vendor.supplier_type] =
          (supplierCounts[vendor.supplier_type] || 0) + 1;
      }
      if (vendor.classification_type) {
        classificationCounts[vendor.classification_type] =
          (classificationCounts[vendor.classification_type] || 0) + 1;
      }
    });

    setSupplierTypeCounts(supplierCounts);
    setClassificationTypeCounts(classificationCounts);
  };

  const handleAddSupplierType = async () => {
    if (!newSupplierType.trim()) {
      alert("Please enter a valid supplier type.");
      return;
    }

    try {
      const response = await axios.post(`${config.BASE_URL}/types/supplier`, {
        supplier_type: newSupplierType,
      });

      setSupplierTypes((prev) => [...prev, response.data.data]);
      setNewSupplierType("");
      alert("Supplier type added successfully.");
    } catch (error) {
      console.error("Error adding supplier type:", error.response?.data || error.message);
      alert("An error occurred while adding the supplier type.");
    }
  };

  const handleAddClassificationType = async () => {
    if (!newClassificationType.trim()) {
      alert("Please enter a valid classification type.");
      return;
    }

    try {
      const response = await axios.post(
        `${config.BASE_URL}/types/classification`,
        { classification_type: newClassificationType }
      );

      setClassificationTypes((prev) => [...prev, response.data.data]);
      setNewClassificationType("");
      alert("Classification type added successfully.");
    } catch (error) {
      console.error("Error adding classification type:", error.response?.data || error.message);
      alert("An error occurred while adding the classification type.");
    }
  };  const handleDeleteType = async (id, type) => {
    try {
      await axios.delete(`${config.BASE_URL}/types/${id}`);
      if (type === "supplier") {
        setSupplierTypes(
          supplierTypes.filter((supplier) => supplier.id !== id)
        );
      } else if (type === "classification") {
        setClassificationTypes(
          classificationTypes.filter(
            (classification) => classification.id !== id
          )
        );
      }
    } catch (error) {
      console.error("Error deleting type:", error);
    }
  };

  const indexOfLastVendor = currentPage * vendorsPerPage;
  const indexOfFirstVendor = indexOfLastVendor - vendorsPerPage;
  const currentVendors = filteredVendors.slice(
    indexOfFirstVendor,
    indexOfLastVendor
  );
  const totalPages = Math.ceil(filteredVendors.length / vendorsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (date) => {
    if (!date || !isValid(parseISO(date))) return "N/A";
    return format(parseISO(date), "MM/dd/yyyy");
  };

  const isExpiringSoon = (endDate) => {
    if (!isValid(parseISO(endDate))) return false;
    const daysLeft = differenceInDays(parseISO(endDate), new Date());
    return daysLeft <= 30 && daysLeft >= 0;
  };

  const isExpired = (endDate) => {
    return isValid(parseISO(endDate)) && new Date() > parseISO(endDate);
  };

  const toggleShowAllSupplierTypes = () => {
    setShowAllSupplierTypes(!showAllSupplierTypes);
  };

  const toggleShowAllClassificationTypes = () => {
    setShowAllClassificationTypes(!showAllClassificationTypes);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = vendors.filter(
      (vendor) =>
        vendor.name.toLowerCase().includes(searchTerm) ||
        (vendor.supplier_type &&
          vendor.supplier_type.toLowerCase().includes(searchTerm)) ||
        (vendor.classification_type &&
          vendor.classification_type.toLowerCase().includes(searchTerm))
    );
    setFilteredVendors(filtered);
    setCurrentPage(1);
  };

  const handleExportToCSV = () => {
    const dataToExport = vendors.map((vendor) => ({
      "Company Name": vendor.name,
      "VAT Certificate": isExpired(vendor.vat_certificate_end)
        ? "Expired"
        : isExpiringSoon(vendor.vat_certificate_end)
        ? "Expiring Soon"
        : "Valid",
      "COC Certificate": isExpired(vendor.coc_certificate_end)
        ? "Expired"
        : isExpiringSoon(vendor.coc_certificate_end)
        ? "Expiring Soon"
        : "Valid",
      "Zakat Certificate": isExpired(vendor.zakat_certificate_end)
        ? "Expired"
        : isExpiringSoon(vendor.zakat_certificate_end)
        ? "Expiring Soon"
        : "Valid",
      "IBAN Certificate": isExpired(vendor.iban_certificate_end)
        ? "Expired"
        : isExpiringSoon(vendor.iban_certificate_end)
        ? "Expiring Soon"
        : "Valid",
      "National Address Certificate": isExpired(vendor.national_address_end)
        ? "Expired"
        : isExpiringSoon(vendor.national_address_end)
        ? "Expiring Soon"
        : "Valid",
    }));

    const csv = Papa.unparse(dataToExport);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "vendors_expiry_status.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="flex-1 p-8 bg-gray-100">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Manage Vendors</h1>

        {/* Search Bar */}
        <div className="flex rounded-full border-2 border-[#741017] overflow-hidden max-w-md mx-auto font-sans">
          <input
            type="text"
            placeholder="Search by Vendor Name, Supplier Type, Classification..."
            className="w-full outline-none bg-white text-sm px-5 py-3 text-gray-600"
            value={searchTerm}
            onChange={handleSearch}
          />
          <button
            type="button"
            className="flex items-center justify-center px-6"
            style={{ backgroundColor: "#741017" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 192.904 192.904"
              width="18px"
              className="fill-white"
            >
              <path d="m190.707 180.101-47.078-47.077c11.702-14.072 18.752-32.142 18.752-51.831C162.381 36.423 125.959 0 81.191 0 36.422 0 0 36.423 0 81.193c0 44.767 36.422 81.187 81.191 81.187 19.688 0 37.759-7.049 51.831-18.751l47.079 47.078a7.474 7.474 0 0 0 5.303 2.197 7.498 7.498 0 0 0 5.303-12.803zM15 81.193C15 44.694 44.693 15 81.191 15c36.497 0 66.189 29.694 66.189 66.193 0 36.496-29.692 66.187-66.189 66.187C44.693 147.38 15 117.689 15 81.193z"></path>
            </svg>
          </button>
        </div>

        {/* Export CSV Button */}
        <button
          onClick={handleExportToCSV}
          className="bg-[#741017] text-white px-4 py-2 rounded"
        >
          Export Expiry Status to CSV
        </button>
      </div>

      {/* Vendor Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-lg font-semibold">Total Vendors</h2>
          <p className="text-4xl font-bold">{totalVendors}</p>
          {vendors.length > 0 ? (
            <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
              Last vendor added:{" "}
              {isValid(new Date(vendors[vendors.length - 1].createdAt))
                ? formatDistanceToNow(
                    new Date(vendors[vendors.length - 1].createdAt),
                    { addSuffix: true }
                  )
                : "No timestamp available"}
            </span>
          ) : (
            <span>No vendors yet</span>
          )}
        </div>

        <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-lg font-semibold">Vendors This Month</h2>
          <p className="text-4xl font-bold">{vendorsThisMonth}</p>
          {vendorsThisMonth > 0 ? (
            <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
              First vendor this month:{" "}
              {isValid(
                new Date(
                  vendors.find((vendor) => {
                    const vendorDate = new Date(vendor.createdAt);
                    return vendorDate.getMonth() === new Date().getMonth();
                  })?.createdAt
                )
              )
                ? formatDistanceToNow(
                    new Date(
                      vendors.find((vendor) => {
                        const vendorDate = new Date(vendor.createdAt);
                        return vendorDate.getMonth() === new Date().getMonth();
                      })?.createdAt
                    ),
                    { addSuffix: true }
                  )
                : "No timestamp available"}
            </span>
          ) : (
            <span>No vendors added this month</span>
          )}
        </div>

        {/* Add Supplier Type */}
        {userRole === "superadmin" && (
          <div className="bg-white p-6 shadow rounded-lg">
            <h2 className="text-lg font-semibold mb-2">
              Add New Supplier Type
            </h2>
            <div className="relative flex items-center">
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 pr-10 w-full"
                value={newSupplierType}
                onChange={(e) => setNewSupplierType(e.target.value)}
                placeholder="Add"
              />
              <button
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white text-sm rounded-full w-8 h-8 flex items-center justify-center"
                style={{ backgroundColor: "#741017" }}
                onClick={handleAddSupplierType}
              >
                +
              </button>
            </div>
            <ul className="mt-2">
              {(showAllSupplierTypes
                ? supplierTypes
                : supplierTypes.slice(0, maxItemsToShow)
              ).map((type, index) => (
                <li key={index}>
                  {type.name}
                  {isValid(new Date(type.createdAt)) && (
                    <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2">
                      {formatDistanceToNow(new Date(type.createdAt), {
                        addSuffix: true,
                      })}
                    </span>
                  )}
                  <button
                    className="ml-2 text-red-500"
                    onClick={() => handleDeleteType(type.id, "supplier")}
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
            {supplierTypes.length > maxItemsToShow && (
              <button
                className="text-blue-500 mt-2"
                onClick={toggleShowAllSupplierTypes}
              >
                {showAllSupplierTypes ? "Show less" : "Show more"}
              </button>
            )}
          </div>
        )}

        {/* Add Classification Type */}
        {userRole === "superadmin" && (
          <div className="bg-white p-6 shadow rounded-lg">
            <h2 className="text-lg font-semibold mb-2">
              Add New Classification Type
            </h2>
            <div className="relative flex items-center">
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 pr-10 w-full"
                value={newClassificationType}
                onChange={(e) => setNewClassificationType(e.target.value)}
                placeholder="Add"
              />
              <button
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white text-sm rounded-full w-8 h-8 flex items-center justify-center"
                style={{ backgroundColor: "#741017" }}
                onClick={handleAddClassificationType}
              >
                +
              </button>
            </div>
            <ul className="mt-2">
              {(showAllClassificationTypes
                ? classificationTypes
                : classificationTypes.slice(0, maxItemsToShow)
              ).map((type, index) => (
                <li key={index}>
                  {type.name}
                  {isValid(new Date(type.createdAt)) && (
                    <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2">
                      {formatDistanceToNow(new Date(type.createdAt), {
                        addSuffix: true,
                      })}
                    </span>
                  )}
                  <button
                    className="ml-2 text-red-500"
                    onClick={() => handleDeleteType(type.id, "classification")}
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
            {classificationTypes.length > maxItemsToShow && (
              <button
                className="text-blue-500 mt-2"
                onClick={toggleShowAllClassificationTypes}
              >
                {showAllClassificationTypes ? "Show less" : "Show more"}
              </button>
            )}
          </div>
        )}
      </div>

      {/* Vendors Table */}
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Vendor Name
              </th>
              <th scope="col" className="px-6 py-3">
                Supplier Type
              </th>
              <th scope="col" className="px-6 py-3">
                Classification Type
              </th>
              <th scope="col" className="px-6 py-3">
                Created
              </th>
              <th scope="col" className="px-6 py-3">
                VAT Certificate
              </th>
              <th scope="col" className="px-6 py-3">
                COC Certificate
              </th>
              <th scope="col" className="px-6 py-3">
                Zakat Certificate
              </th>
              <th scope="col" className="px-6 py-3">
                IBAN Certificate
              </th>
              <th scope="col" className="px-6 py-3">
                National Address Certificate
              </th>
            </tr>
          </thead>
          <tbody>
            {currentVendors.map((vendor) => (
              <tr
                key={vendor._id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {vendor.name}
                </th>
                <td className="px-6 py-4">{vendor.supplier_type || "N/A"}</td>
                <td className="px-6 py-4">
                  {vendor.classification_type || "N/A"}
                </td>
                <td className="px-6 py-4">{formatDate(vendor.createdAt)}</td>

                {/* VAT Certificate */}
                <td className="px-6 py-4">
                  {vendor.vat_certificate_start &&
                  vendor.vat_certificate_end ? (
                    <div className="flex items-center">
                      <span className="mr-4">
                        {`Start: ${formatDate(
                          vendor.vat_certificate_start
                        )}, End: ${formatDate(vendor.vat_certificate_end)}`}
                      </span>
                      <span
                        className={`flex w-2.5 h-2.5 rounded-full me-1.5 flex-shrink-0 ${
                          isExpired(vendor.vat_certificate_end)
                            ? "bg-red-600"
                            : isExpiringSoon(vendor.vat_certificate_end)
                            ? "bg-yellow-600"
                            : "bg-green-600"
                        }`}
                        title={`${
                          isExpired(vendor.vat_certificate_end)
                            ? "Expired"
                            : isExpiringSoon(vendor.vat_certificate_end)
                            ? "Expiring Soon"
                            : "Valid"
                        }`}
                      ></span>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/* COC Certificate */}
                <td className="px-6 py-4">
                  {vendor.coc_certificate_start &&
                  vendor.coc_certificate_end ? (
                    <div className="flex items-center">
                      <span className="mr-4">
                        {`Start: ${formatDate(
                          vendor.coc_certificate_start
                        )}, End: ${formatDate(vendor.coc_certificate_end)}`}
                      </span>
                      <span
                        className={`flex w-2.5 h-2.5 rounded-full me-1.5 flex-shrink-0 ${
                          isExpired(vendor.coc_certificate_end)
                            ? "bg-red-600"
                            : isExpiringSoon(vendor.coc_certificate_end)
                            ? "bg-yellow-600"
                            : "bg-green-600"
                        }`}
                        title={`${
                          isExpired(vendor.coc_certificate_end)
                            ? "Expired"
                            : isExpiringSoon(vendor.coc_certificate_end)
                            ? "Expiring Soon"
                            : "Valid"
                        }`}
                      ></span>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/* Zakat Certificate */}
                <td className="px-6 py-4">
                  {vendor.zakat_certificate_start &&
                  vendor.zakat_certificate_end ? (
                    <div className="flex items-center">
                      <span className="mr-4">
                        {`Start: ${formatDate(
                          vendor.zakat_certificate_start
                        )}, End: ${formatDate(vendor.zakat_certificate_end)}`}
                      </span>
                      <span
                        className={`flex w-2.5 h-2.5 rounded-full me-1.5 flex-shrink-0 ${
                          isExpired(vendor.zakat_certificate_end)
                            ? "bg-red-600"
                            : isExpiringSoon(vendor.zakat_certificate_end)
                            ? "bg-yellow-600"
                            : "bg-green-600"
                        }`}
                        title={`${
                          isExpired(vendor.zakat_certificate_end)
                            ? "Expired"
                            : isExpiringSoon(vendor.zakat_certificate_end)
                            ? "Expiring Soon"
                            : "Valid"
                        }`}
                      ></span>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/* IBAN Certificate */}
                <td className="px-6 py-4">
                  {vendor.iban_certificate_start &&
                  vendor.iban_certificate_end ? (
                    <div className="flex items-center">
                      <span className="mr-4">
                        {`Start: ${formatDate(
                          vendor.iban_certificate_start
                        )}, End: ${formatDate(vendor.iban_certificate_end)}`}
                      </span>
                      <span
                        className={`flex w-2.5 h-2.5 rounded-full me-1.5 flex-shrink-0 ${
                          isExpired(vendor.iban_certificate_end)
                            ? "bg-red-600"
                            : isExpiringSoon(vendor.iban_certificate_end)
                            ? "bg-yellow-600"
                            : "bg-green-600"
                        }`}
                        title={`${
                          isExpired(vendor.iban_certificate_end)
                            ? "Expired"
                            : isExpiringSoon(vendor.iban_certificate_end)
                            ? "Expiring Soon"
                            : "Valid"
                        }`}
                      ></span>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/* National Address Certificate */}
                <td className="px-6 py-4">
                  {vendor.national_address_start &&
                  vendor.national_address_end ? (
                    <div className="flex items-center">
                      <span className="mr-4">
                        {`Start: ${formatDate(
                          vendor.national_address_start
                        )}, End: ${formatDate(vendor.national_address_end)}`}
                      </span>
                      <span
                        className={`flex w-2.5 h-2.5 rounded-full me-1.5 flex-shrink-0 ${
                          isExpired(vendor.national_address_end)
                            ? "bg-red-600"
                            : isExpiringSoon(vendor.national_address_end)
                            ? "bg-yellow-600"
                            : "bg-green-600"
                        }`}
                        title={`${
                          isExpired(vendor.national_address_end)
                            ? "Expired"
                            : isExpiringSoon(vendor.national_address_end)
                            ? "Expiring Soon"
                            : "Valid"
                        }`}
                      ></span>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <nav
        className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          Showing{" "}
          <span className="font-semibold text-gray-900 dark:text-white">
            {indexOfFirstVendor + 1}-
            {Math.min(indexOfLastVendor, filteredVendors.length)}
          </span>{" "}
          of{" "}
          <span className="font-semibold text-gray-900 dark:text-white">
            {filteredVendors.length}
          </span>
        </span>
        <ul className="inline-flex items-stretch -space-x-px">
          <li>
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index + 1}>
              <button
                onClick={() => paginate(index + 1)}
                className={`flex items-center justify-center text-sm py-2 px-3 leading-tight ${
                  currentPage === index + 1
                    ? "text-primary-600 bg-primary-50 border border-primary-300"
                    : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                } dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default ManageVendor;
