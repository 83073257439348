import React from 'react';
import CRUDTable from '../dashboard/CRUDTable';

const Dashboard = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4"></h1>
      <CRUDTable />
    </div>
  );
};

export default Dashboard;
