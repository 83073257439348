import React, { useState, useEffect } from "react";
import axios from "axios";
import myGif from "../assets/image.gif";
import config from "../config";
import SuccessAlertBox from "./SuccessAlertBox.js";
import ErrorAlertBox from "./ErrorAlertBox";

const VendorForm = () => {
  const [formData, setFormData] = useState({
    // Full Company Details Section
    name: "", // Full Company Name
    phone_extension: "", // Phone Extension
    phone: "", // Phone Number
    email: "", // Email

    // Address Details Section
    address_line_1: "", // Address Line 1
    address_line_2: "", // Address Line 2
    city: "", // City
    state: "", // State
    postcode: "", // Postcode
    country: "", // Country
    website: "", // Corporate Website
    fax_extension: "", // Fax Extension
    fax: "", // Fax Number

    // Vendor Type Section
    vendor_type: "", // Vendor Type ('local' or 'foreign')

    // Local Vendor Specific Fields
    local_vendor_name: "", // Local Vendor Name
    customer_account: "", // Customer Account
    short_address: "", // Short Address
    building_no: "", // Building Number
    street_secondary_number: "", // Street Secondary Number
    district: "", // District

    // Registration Details Section
    contactable_person_name: "", // Register/Contactable Person Name (updated field)
    register_email: "", // Register Email
    cr_number: "", // CR Number
    supplier_type: "", // Supplier Type
    classification_type: "", // Classification Type
    taxpayer_id: "", // Taxpayer ID
    tax_country: "", // Tax Country
    vat_number: "", // VAT Number

    // Bank Details Section
    bank_name: "", // Bank Name
    account_name: "", // Account Name
    account_number: "", // Account Number
    branch: "", // Branch
    iban: "", // IBAN
    currency: "", // Currency

    // Certificates Section
    company_profile: null, // Company Profile File
    pre_qualification: null, // Pre-qualification File
    vat_certificate_start: "", // VAT Certificate Start Date
    vat_certificate_end: "", // VAT Certificate End Date
    vat_certificate: null, // VAT Certificate File
    coc_certificate_start: "", // COC Certificate Start Date
    coc_certificate_end: "", // COC Certificate End Date
    coc_certificate: null, // COC Certificate File
    zakat_certificate_start: "", // Zakat Certificate Start Date
    zakat_certificate_end: "", // Zakat Certificate End Date
    zakat_certificate: null, // Zakat Certificate File
    iban_certificate_start: "", // IBAN Certificate Start Date
    iban_certificate_end: "", // IBAN Certificate End Date
    iban_certificate: null, // IBAN Certificate File
    national_address_start: "", // National Address Certificate Start Date
    national_address_end: "", // National Address Certificate End Date
    national_address_certificate: null, // National Address Certificate File
  });

  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [supplierTypes, setSupplierTypes] = useState([]);
  const [classificationTypes, setClassificationTypes] = useState([]);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [alertData, setAlertData] = useState({ title: "", message: "" });

  const showModal = (type, title, message) => {
    setAlertData({ title, message });
    if (type === "success") setIsSuccessVisible(true);
    if (type === "error") setIsErrorVisible(true);
  };

  // Fetch supplier and classification types from the backend
  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}/types`);
        const types = response.data.data;

        // Filter and format types
        const suppliers = types
          .filter((type) => type.supplier_type)
          .map((type) => ({ id: type._id, name: type.supplier_type }));
        const classifications = types
          .filter((type) => type.classification_type)
          .map((type) => ({ id: type._id, name: type.classification_type }));

        setSupplierTypes(suppliers);
        setClassificationTypes(classifications);
      } catch (error) {
        console.error("Error fetching types:", error);
      }
    };

    fetchTypes();
  }, []);

  // Fetch countries, extensions, and currencies
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        // Fetch the country data from the backend API
        const response = await axios.get(`${config.BASE_URL}/countries`); // Use your backend URL
        const countryData = response.data;

        // Process country data
        const processedCountries = countryData.map((country) => ({
          name: country.name || "Unknown",
          code: country.code || "",
          extension: country.phoneExtension || "",
          faxExtension: country.faxExtension || "",
          currency: country.currency || "",
        }));

        // Set countries and currencies states
        setCountries(processedCountries);
        setCurrencies([
          ...new Set(processedCountries.map((country) => country.currency)),
        ]); // Unique list of currencies
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountryData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    const maxSize = 2 * 1024 * 1024;

    if (file && file.size > maxSize) {
      alert("File size exceeds the 2MB limit. Please upload a smaller file.");
      setFileSizeExceeded(true);
      return;
    }

    setFileSizeExceeded(false);
    setFormData({
      ...formData,
      [name]: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fileSizeExceeded) {
      showModal(
        "error",
        "File Size Error",
        "File size exceeds the 2MB limit. Please upload a smaller file."
      );
      return;
    }

    const formDataToSend = new FormData();

    // List of fields not applicable for foreign vendors
    const foreignExcludedFields = [
      "local_vendor_name",
      "customer_account",
      "short_address",
      "building_no",
      "street_secondary_number",
      "district",
    ];

    // Add fields dynamically, skipping non-applicable fields for foreign vendors
    Object.keys(formData).forEach((key) => {
      if (
        formData.vendor_type === "foreign" &&
        foreignExcludedFields.includes(key)
      ) {
        return;
      }

      // Handle file inputs
      if (formData[key] instanceof FileList) {
        Array.from(formData[key]).forEach((file) => {
          formDataToSend.append(key, file);
        });
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      const response = await axios.post(
        `${config.BASE_URL}/vendors/register`,
        formDataToSend,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      // Show success modal
      showModal(
        "success",
        "Successfully Accepted!",
        `Vendor created successfully! Vendor ID: ${response.data.vendor.vendorID}`
      );

      // Reset the form state
      setFormData({
        name: "",
        phone_extension: "",
        phone: "",
        email: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        postcode: "",
        country: "",
        website: "",
        fax_extension: "",
        fax: "",
        vendor_type: "",
        local_vendor_name: "",
        customer_account: "",
        short_address: "",
        building_no: "",
        street_secondary_number: "",
        district: "",
        contactable_person_name: "",
        register_email: "",
        cr_number: "",
        supplier_type: "",
        classification_type: "",
        taxpayer_id: "",
        tax_country: "",
        vat_number: "",
        bank_name: "",
        account_name: "",
        account_number: "",
        branch: "",
        iban: "",
        currency: "",
        company_profile: null,
        pre_qualification: null,
        vat_certificate_start: "",
        vat_certificate_end: "",
        vat_certificate: null,
        coc_certificate_start: "",
        coc_certificate_end: "",
        coc_certificate: null,
        zakat_certificate_start: "",
        zakat_certificate_end: "",
        zakat_certificate: null,
        iban_certificate_start: "",
        iban_certificate_end: "",
        iban_certificate: null,
        national_address_start: "",
        national_address_end: "",
        national_address_certificate: null,
      });
      setFileSizeExceeded(false);
    } catch (error) {
      let errorMessage = "Something went wrong. Please try again later.";

      // Check if the backend provides specific error messages
      if (error.response?.data?.error) {
        const backendError = error.response.data.error;

        if (backendError === "Email already exists") {
          errorMessage = "A vendor with this email already exists.";
        } else if (backendError === "VAT number already exists") {
          errorMessage = "A vendor with this VAT number already exists.";
        } else if (backendError === "File size exceeds limit") {
          errorMessage = "Uploaded file exceeds the size limit.";
        } else {
          errorMessage = backendError; // Default error from backend
        }
      }

      showModal("error", "Error", errorMessage);
    }
  };

  return (
    <div className="bg-white font-[sans-serif] md:h-screen">
      <div className="grid h-full items-center gap-0 md:grid-cols-2">
        {" "}
        {/* Removed gap for no spacing */}
        <div className="h-full bg-gray-50 p-0">
          {" "}
          {/* Removed padding to make it flush */}
          <img
            src={myGif}
            className="mx-auto block h-full w-full object-contain lg:max-w-full"
            alt="login-image"
          />
        </div>
        <div className="flex h-full w-full items-center p-6">
          {" "}
          {/* Kept padding for form area */}
          <form
            onSubmit={handleSubmit}
            className="mx-auto max-h-[100vh] w-full max-w-[1200px] overflow-y-scroll rounded-lg border border-gray-200 bg-white p-4 shadow-md"
          >
            <div className="mb-6">
              <h3 className="text-2xl font-extrabold text-[#6b6a6a] max-md:text-center md:text-3xl">
                Vendor Registration
              </h3>
              <p className="mt-2 text-sm text-gray-600 max-md:text-center">
                Register as an official vendor and gain access to exclusive
                opportunities. Join a network of trusted suppliers by completing
                the form below.
              </p>
            </div>
            {/* Animated SVG at the end of the form */}
            <div className="flex justify-center mt-6">
              <svg
                className="animate-bounce w-8 h-8 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>

            {/* Full Company Name */}
            {/* Name Field */}
            <div className="mb-6">
              <label className="mb-2 block text-xs text-gray-800">
                Full Company Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
              />
            </div>

            {/* Phone Number */}
            <div className="mb-6 mt-6">
              <label className="mb-2 block text-xs text-gray-800">
                Phone Number
              </label>
              <div className="flex">
                <select
                  name="phone_extension"
                  value={formData.phone_extension}
                  onChange={handleChange}
                  required
                  className="w-1/4 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                >
                  <option value="">Ext</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.extension}>
                      {country.extension}
                    </option>
                  ))}
                </select>
                <input
                  name="phone"
                  type="text"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-3/4 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter your phone number"
                />
              </div>
            </div>

            {/* Email */}
            <div className="mb-6">
              <label className="mb-2 block text-xs text-gray-800">Email</label>
              <input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                placeholder="Enter your email"
              />
            </div>

            {/* Address Details */}
            <h3 className="text-lg font-bold text-gray-800">Address Details</h3>

            {/* Vendor Type Selection */}
            <div className="inline-block relative w-full mt-6 mb-6">
              <label className="mb-2 block text-xs text-gray-800">
                Vendor Type
              </label>
              <select
                name="vendor_type"
                value={formData.vendor_type}
                onChange={handleChange}
                required
                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">Select Vendor Type</option>
                <option value="local">Local Vendor</option>
                <option value="foreign">Foreign Vendor</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>

            {/* Fields for Local Vendor */}
            {formData.vendor_type === "local" && (
              <>
                {/* Local Vendor Name */}
                <div className="mb-6">
                  <label className="mb-2 block text-xs text-gray-800">
                    Local Vendor Name
                  </label>
                  <input
                    name="local_vendor_name"
                    type="text"
                    value={formData.local_vendor_name || ""}
                    onChange={handleChange}
                    required
                    className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                    placeholder="Enter Local Vendor Name"
                  />
                </div>

                {/* Customer Account */}
                <div className="mb-6">
                  <label className="mb-2 block text-xs text-gray-800">
                    Customer Account
                  </label>
                  <input
                    name="customer_account"
                    type="text"
                    value={formData.customer_account || ""}
                    onChange={handleChange}
                    required
                    className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                    placeholder="Enter Customer Account"
                  />
                </div>

                {/* Short Address */}
                <div className="mb-6">
                  <label className="mb-2 block text-xs text-gray-800">
                    Short Address
                  </label>
                  <input
                    name="short_address"
                    type="text"
                    value={formData.short_address || ""}
                    onChange={handleChange}
                    required
                    className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                    placeholder="Enter Short Address"
                  />
                </div>

                {/* Building Number */}
                <div className="mb-6">
                  <label className="mb-2 block text-xs text-gray-800">
                    Building Number
                  </label>
                  <input
                    name="building_no"
                    type="text"
                    value={formData.building_no || ""}
                    onChange={handleChange}
                    required
                    className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                    placeholder="Enter Building Number"
                  />
                </div>

                {/* Street Secondary Number */}
                <div className="mb-6">
                  <label className="mb-2 block text-xs text-gray-800">
                    Street Secondary Number
                  </label>
                  <input
                    type="text"
                    name="street_secondary_number"
                    value={formData.street_secondary_number}
                    onChange={handleChange}
                    className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  />
                </div>

                {/* District */}
                <div className="mb-6">
                  <label className="mb-2 block text-xs text-gray-800">
                    District
                  </label>
                  <input
                    name="district"
                    type="text"
                    value={formData.district || ""}
                    onChange={handleChange}
                    required
                    className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                    placeholder="Enter District"
                  />
                </div>
              </>
            )}

            <div className="mb-6">
              <label className="mb-2 block text-xs text-gray-800">
                Address Line 1
              </label>
              <input
                name="address_line_1"
                type="text"
                value={formData.address_line_1}
                onChange={handleChange}
                required
                className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                placeholder="Enter address line 1"
              />
            </div>
            <div className="mb-6">
              <label className="mb-2 block text-xs text-gray-800">
                Address Line 2
              </label>
              <input
                name="address_line_2"
                type="text"
                value={formData.address_line_2}
                onChange={handleChange}
                className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                placeholder="Enter address line 2"
              />
            </div>
            <div className="mb-6">
              <label className="mb-2 block text-xs text-gray-800">City</label>
              <input
                name="city"
                type="text"
                value={formData.city}
                onChange={handleChange}
                required
                className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                placeholder="Enter city"
              />
            </div>
            <div className="mb-6">
              <label className="mb-2 block text-xs text-gray-800">State</label>
              <input
                name="state"
                type="text"
                value={formData.state}
                onChange={handleChange}
                required
                className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                placeholder="Enter state"
              />
            </div>
            <div className="mb-6">
              <label className="mb-2 block text-xs text-gray-800">
                Post Code
              </label>
              <input
                name="postcode"
                type="text"
                value={formData.postcode}
                onChange={handleChange}
                required
                className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                placeholder="Enter postcode"
              />
            </div>
            {/* Country Dropdown */}
            <div className="mb-6 mt-6">
              <label className="mb-2 block text-xs text-gray-800">
                Country
              </label>
              <select
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
                className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Corporate Website */}
            <div className="mb-6">
              <label className="mb-2 block text-xs text-gray-800">
                Corporate Website
              </label>
              <input
                name="website"
                type="text"
                value={formData.website}
                onChange={handleChange}
                className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                placeholder="Enter corporate website"
              />
            </div>

            {/* Fax Number */}
            <div className="mb-6">
              <label className="mb-2 block text-xs text-gray-800">
                Fax Number
              </label>
              <div className="flex">
                <select
                  name="fax_extension"
                  value={formData.fax_extension}
                  onChange={handleChange}
                  className="w-1/4 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                >
                  <option value="">Ext</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.extension}>
                      {country.extension}
                    </option>
                  ))}
                </select>
                <input
                  name="fax"
                  type="text"
                  value={formData.fax}
                  onChange={handleChange}
                  required
                  className="w-3/4 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter fax number"
                />
              </div>
            </div>

            {/* Company Details */}
            <div className="mb-12">
              <h3 className="text-lg font-bold text-gray-800">
                Company Details
              </h3>

              {/* Register/Contactable Person Name */}
              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Registerer/Contactable Person Name
                </label>
                <input
                  name="contactable_person_name"
                  type="text"
                  value={formData.contactable_person_name}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter contactable person name"
                />
              </div>

              {/* Register Email */}
              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Registerer Email
                </label>
                <input
                  name="register_email"
                  type="email"
                  value={formData.register_email}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter registerer email"
                />
              </div>

              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">
                  CR Number
                </label>
                <input
                  name="cr_number"
                  type="text"
                  value={formData.cr_number}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter CR number"
                />
              </div>

              {/* Supplier Type */}
              <div className="mt-6 mb-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Supplier Type
                </label>
                <select
                  name="supplier_type"
                  value={formData.supplier_type}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                >
                  <option value="">Select Supplier Type</option>
                  {supplierTypes.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Classification Type */}
              <div className="mb-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Classification Type
                </label>
                <select
                  name="classification_type"
                  value={formData.classification_type}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                >
                  <option value="">Select Classification Type</option>
                  {classificationTypes.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Tax Payer ID
                </label>
                <input
                  name="taxpayer_id"
                  type="text"
                  value={formData.taxpayer_id}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter Tax Payer ID"
                />
              </div>
              {/* Tax Country Dropdown */}
              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Tax Country
                </label>
                <select
                  name="tax_country"
                  value={formData.tax_country}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                >
                  <option value="">Select Tax Country</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">
                  VAT Number
                </label>
                <input
                  name="vat_number"
                  type="text"
                  value={formData.vat_number}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter VAT Number"
                />
              </div>
            </div>

            {/* Bank Details */}
            <div className="mb-12">
              <h3 className="text-lg font-bold text-gray-800">Bank Details</h3>
              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Bank Name
                </label>
                <input
                  name="bank_name"
                  type="text"
                  value={formData.bank_name}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter Bank Name"
                />
              </div>
              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Account Name
                </label>
                <input
                  name="account_name"
                  type="text"
                  value={formData.account_name}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter Account Name"
                />
              </div>
              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Account Number
                </label>
                <input
                  name="account_number"
                  type="text"
                  value={formData.account_number}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter Account Number"
                />
              </div>
              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Branch
                </label>
                <input
                  name="branch"
                  type="text"
                  value={formData.branch}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter Branch"
                />
              </div>
              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">IBAN</label>
                <input
                  name="iban"
                  type="text"
                  value={formData.iban}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  placeholder="Enter IBAN"
                />
              </div>
              {/* Currency Dropdown */}
              <div className="mt-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Currency
                </label>
                <select
                  name="currency"
                  value={formData.currency}
                  onChange={handleChange}
                  required
                  className="w-full border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                >
                  <option value="">Select Currency</option>
                  {currencies.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Certificates */}
            <div className="mb-12">
              <h3 className="text-lg font-bold text-gray-800">Certificates</h3>

              {/* New Company Profile Field */}
              <div className="mb-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Company Profile
                </label>
                <input
                  name="company_profile"
                  type="file"
                  onChange={handleFileChange}
                  required
                  className="w-full border-b border-gray-300 px-2 py-3 text-sm outline-none focus:border-blue-500"
                />
              </div>

              {/* New Pre-qualification Field */}
              <div className="mb-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Pre-qualification
                </label>
                <input
                  name="pre_qualification"
                  type="file"
                  onChange={handleFileChange}
                  required
                  className="w-full border-b border-gray-300 px-2 py-3 text-sm outline-none focus:border-blue-500"
                />
              </div>

              {/* VAT Certificate Section */}
              <div className="mb-6">
                <label className="mb-2 block text-xs text-gray-800">
                  VAT Certificate Dates
                </label>
                <div className="flex space-x-2">
                  <input
                    name="vat_certificate_start"
                    type="date"
                    value={formData.vat_certificate_start}
                    onChange={handleChange}
                    required
                    className="w-1/2 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  />
                  <input
                    name="vat_certificate_end"
                    type="date"
                    value={formData.vat_certificate_end}
                    onChange={handleChange}
                    required
                    className="w-1/2 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  />
                </div>
                <input
                  name="vat_certificate"
                  type="file"
                  onChange={handleFileChange}
                  required
                  className="mt-2 w-full border-b border-gray-300 px-2 py-3 text-sm outline-none focus:border-blue-500"
                />
              </div>

              {/* COC Certificate Section */}
              <div className="mb-6">
                <label className="mb-2 block text-xs text-gray-800">
                  COC Certificate Dates
                </label>
                <div className="flex space-x-2">
                  <input
                    name="coc_certificate_start"
                    type="date"
                    value={formData.coc_certificate_start}
                    onChange={handleChange}
                    required
                    className="w-1/2 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  />
                  <input
                    name="coc_certificate_end"
                    type="date"
                    value={formData.coc_certificate_end}
                    onChange={handleChange}
                    required
                    className="w-1/2 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  />
                </div>
                <input
                  name="coc_certificate"
                  type="file"
                  onChange={handleFileChange}
                  required
                  className="mt-2 w-full border-b border-gray-300 px-2 py-3 text-sm outline-none focus:border-blue-500"
                />
              </div>

              {/* Zakat Certificate Section */}
              <div className="mb-6">
                <label className="mb-2 block text-xs text-gray-800">
                  Zakat Certificate Dates
                </label>
                <div className="flex space-x-2">
                  <input
                    name="zakat_certificate_start"
                    type="date"
                    value={formData.zakat_certificate_start}
                    onChange={handleChange}
                    required
                    className="w-1/2 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  />
                  <input
                    name="zakat_certificate_end"
                    type="date"
                    value={formData.zakat_certificate_end}
                    onChange={handleChange}
                    required
                    className="w-1/2 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  />
                </div>
                <input
                  name="zakat_certificate"
                  type="file"
                  onChange={handleFileChange}
                  required
                  className="mt-2 w-full border-b border-gray-300 px-2 py-3 text-sm outline-none focus:border-blue-500"
                />
              </div>

              {/* IBAN Certificate Section */}
              <div className="mb-6">
                <label className="mb-2 block text-xs text-gray-800">
                  IBAN Certificate Dates
                </label>
                <div className="flex space-x-2">
                  <input
                    name="iban_certificate_start"
                    type="date"
                    value={formData.iban_certificate_start}
                    onChange={handleChange}
                    required
                    className="w-1/2 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  />
                  <input
                    name="iban_certificate_end"
                    type="date"
                    value={formData.iban_certificate_end}
                    onChange={handleChange}
                    required
                    className="w-1/2 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  />
                </div>
                <input
                  name="iban_certificate"
                  type="file"
                  onChange={handleFileChange}
                  required
                  className="mt-2 w-full border-b border-gray-300 px-2 py-3 text-sm outline-none focus:border-blue-500"
                />
              </div>

              {/* National Address Certificate Section */}
              <div className="mb-6">
                <label className="mb-2 block text-xs text-gray-800">
                  National Address Certificate Dates
                </label>
                <div className="flex space-x-2">
                  <input
                    name="national_address_start"
                    type="date"
                    value={formData.national_address_start}
                    onChange={handleChange}
                    required
                    className="w-1/2 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  />
                  <input
                    name="national_address_end"
                    type="date"
                    value={formData.national_address_end}
                    onChange={handleChange}
                    required
                    className="w-1/2 border-b border-gray-300 bg-transparent px-2 py-3 text-sm outline-none focus:border-blue-500"
                  />
                </div>
                <input
                  name="national_address_certificate"
                  type="file"
                  onChange={handleFileChange}
                  required
                  className="mt-2 w-full border-b border-gray-300 px-2 py-3 text-sm outline-none focus:border-blue-500"
                />
              </div>
            </div>

            {/* Submit */}
            <div className="mt-12">
              <button
                type="submit"
                className="w-full rounded-md bg-[#741017] px-6 py-3 text-sm font-semibold tracking-wider text-white hover:bg-gray-600 focus:outline-none focus:ring-4 focus:ring-[#741017]"
              >
                Submit Form
              </button>
            </div>

            <SuccessAlertBox
              isVisible={isSuccessVisible}
              onClose={() => setIsSuccessVisible(false)}
              title={alertData.title}
              message={alertData.message}
            />

            <ErrorAlertBox
              isVisible={isErrorVisible}
              onClose={() => setIsErrorVisible(false)}
              title={alertData.title}
              message={alertData.message}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default VendorForm;
