import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/logo.png";
import config from "../../config";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [rememberMe, setRememberMe] = useState(false); // State for "Remember me" checkbox
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Load saved email from localStorage if "Remember me" was previously checked
  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Please provide both email and password");
      return;
    }

    try {
      const response = await axios.post(`${config.BASE_URL}/auth/login`, {
        email,
        password,
      });

      if (response.data.token) {
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("userRole", response.data.user.role);

        // Remember email if "Remember me" is checked
        if (rememberMe) {
          localStorage.setItem("rememberedEmail", email);
        } else {
          localStorage.removeItem("rememberedEmail");
        }

        navigate("/dashboard");
      } else {
        setError("Invalid credentials");
      }
    } catch (err) {
      setError("Invalid credentials");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="font-[sans-serif]">
      <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
        <div className="grid md:grid-cols-2 items-center gap-4 max-w-6xl w-full">
          <div
            className="border border-gray-300 rounded-lg p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto"
            style={{ borderColor: "#6b6a6a" }}
          >
            <form className="space-y-4" onSubmit={handleLogin}>
              <div className="mb-8">
                <h3
                  className="text-3xl font-extrabold"
                  style={{ color: "#6b6a6a" }}
                >
                  Sign in
                </h3>
                <p
                  className="text-sm mt-4 leading-relaxed"
                  style={{ color: "#6b6a6a" }}
                >
                  Sign in to your account and explore a world of possibilities.
                  Your journey begins here.
                </p>
              </div>
              {error && <p className="text-red-500 mb-4">{error}</p>}
              <div>
                <label
                  className="text-sm mb-2 block"
                  style={{ color: "#6b6a6a" }}
                >
                  Email
                </label>
                <div className="relative flex items-center">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full text-sm px-4 py-3 rounded-lg outline-none border border-gray-300"
                    placeholder="Enter email"
                    required
                    style={{ color: "#6b6a6a", borderColor: "#6b6a6a" }}
                  />
                </div>
              </div>
              <div>
                <label
                  className="text-sm mb-2 block"
                  style={{ color: "#6b6a6a" }}
                >
                  Password
                </label>
                <div className="relative flex items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full text-sm px-4 py-3 rounded-lg outline-none border border-gray-300"
                    placeholder="Enter password"
                    required
                    style={{ color: "#6b6a6a", borderColor: "#6b6a6a" }}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#6b6a6a"
                    stroke="#6b6a6a"
                    className="w-[18px] h-[18px] absolute right-4 cursor-pointer"
                    viewBox="0 0 128 128"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <path d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"></path>
                    ) : (
                      <path d="M10 64c6.865-9.178 30.012-36 54-36s47.135 26.822 54 36c-6.865 9.178-30.012 36-54 36S16.865 73.178 10 64zm54-8c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16z"></path>
                    )}
                  </svg>
                </div>
              </div>

              <div className="flex flex-wrap items-center justify-between gap-4">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 shrink-0 text-[#741017] focus:ring-blue-500 border-gray-300 rounded"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm"
                    style={{ color: "#6b6a6a" }}
                  >
                    Remember me
                  </label>
                </div>
              </div>

              <div className="!mt-8">
                <button
                  type="submit"
                  className="w-full shadow-xl py-3 px-4 text-sm tracking-wide rounded-lg text-white"
                  style={{ backgroundColor: "#741017" }}
                >
                  Log in
                </button>
              </div>
            </form>
          </div>

          {/* Logo Image in the Login Section */}
          <div className="lg:h-[400px] md:h-[300px] max-md:mt-8">
            <img
              src={logo}
              className="w-full h-full max-md:w-4/5 mx-auto block object-cover"
              alt="Company Logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
