import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Footer from './components/Footer';
import VendorForm from './components/VendorForm'; // Publicly accessible vendor form
import Sidebar from './components/dashboard/Sidebar';
import Dashboard from './components/pages/Dashboard';
import ManageVendor from './components/dashboard/ManageVendor';
import Reports from './components/dashboard/Reports';
import UserManagement from './components/dashboard/User';
import CreateUser from './components/dashboard/CreateUser';
import LoginPage from './components/dashboard/LoginPage';
import PrivateRoute from './components/dashboard/PrivateRoute';
import DashboardHeader from './components/dashboard/Header'; // Import Dashboard Header
import Header from './components/Header'; // Import Vendor Form Header
import PrivacyPolicy from './components/PrivacyPolicy'; // Import Privacy Policy Modal

const App = () => {
  const location = useLocation();
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(true); // Always show modal on refresh

  // Define which routes should not display Sidebar and Header
  const hideSidebar = location.pathname === '/' || location.pathname === '/login';
  const showHeaderOnly = location.pathname === '/';

  const handlePrivacyAction = (action) => {
    const timestamp = new Date().toISOString();
    console.log(`Action: ${action}, Timestamp: ${timestamp}`);
    setShowPrivacyPolicy(false);
  };

  return (
    <div className="App flex">
      {/* Conditionally render Sidebar based on the current route */}
      {!hideSidebar && (
        <Sidebar className="sidebar fixed top-0 left-0 h-full w-56 bg-white shadow-md z-10" />
      )}

      <div className={hideSidebar ? 'flex-grow' : 'main-content flex-grow ml-56'}>
        {/* Conditionally render VendorForm Header for VendorForm only */}
        {showHeaderOnly && <Header />}

        <Routes>
          {/* Public Route for Vendor Form (with VendorForm Header but no Sidebar) */}
          <Route
            path="/"
            element={
              <>
                <main className="container mx-auto p-4 max-w-4xl">
                  <VendorForm />
                </main>
                <Footer />
              </>
            }
          />

          {/* Login Route (No Header or Sidebar) */}
          <Route path="/login" element={<LoginPage />} />

          {/* Dashboard Route (Accessible by any logged-in user, includes DashboardHeader and Sidebar) */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <div className="flex flex-col min-h-screen">
                  <DashboardHeader /> {/* Dashboard Header */}
                  <main className="flex-grow p-4">
                    <Dashboard />
                  </main>
                </div>
              </PrivateRoute>
            }
          />

          {/* Manage Vendors (Accessible by Admin and Superadmin, includes DashboardHeader and Sidebar) */}
          <Route
            path="/manage-vendors"
            element={
              <PrivateRoute>
                <div className="flex flex-col min-h-screen">
                  <DashboardHeader />
                  <main className="flex-grow p-4">
                    <ManageVendor />
                  </main>
                </div>
              </PrivateRoute>
            }
          />

          {/* Reports (Accessible by Admin and Superadmin, includes DashboardHeader and Sidebar) */}
          <Route
            path="/reports"
            element={
              <PrivateRoute>
                <div className="flex flex-col min-h-screen">
                  <DashboardHeader />
                  <main className="flex-grow p-4">
                    <Reports />
                  </main>
                </div>
              </PrivateRoute>
            }
          />

          {/* User Management (Accessible by Superadmin only, includes DashboardHeader and Sidebar) */}
          <Route
            path="/user-management"
            element={
              <PrivateRoute requiredRole="superadmin">
                <div className="flex flex-col min-h-screen">
                  <DashboardHeader />
                  <main className="flex-grow p-4">
                    <UserManagement />
                  </main>
                </div>
              </PrivateRoute>
            }
          />

          {/* Create User Page (Accessible by Superadmin only, includes DashboardHeader and Sidebar) */}
          <Route
            path="/create-user"
            element={
              <PrivateRoute requiredRole="superadmin">
                <div className="flex flex-col min-h-screen">
                  <DashboardHeader />
                  <main className="flex-grow p-4">
                    <CreateUser />
                  </main>
                </div>
              </PrivateRoute>
            }
          />
        </Routes>
      </div>

      {/* Always Render the Privacy Policy Modal */}
      {showPrivacyPolicy && (
        <PrivacyPolicy
          onAction={(action) => handlePrivacyAction(action)}
        />
      )}
    </div>
  );
};

export default () => (
  <Router>
    <App />
  </Router>
);
