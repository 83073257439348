
const config = {
  BASE_URL:
    process.env.REACT_APP_BASE_URL || // Use environment variable if set
    (process.env.NODE_ENV === "production"
      ? "https://vrshar.com/api" // Production: Use HTTPS with /api
      : "https://vrshar.com/api"), // Development: Use HTTP with /api
};

export default config;
