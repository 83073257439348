import React, { useState } from 'react';
import axios from 'axios';
import myGif from '../../assets/user.gif'; // Adjusted path based on your directory structure
import config from '../../config'; // Importing config file

const AddUser = () => {
  const [newUser, setNewUser] = useState({ name: '', email: '', password: '', role: 'user' });
  const [message, setMessage] = useState('');

  // Handle user creation
  const handleAddUser = async () => {
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('authToken');

      // If the token doesn't exist, alert the user to log in
      if (!token) {
        alert('You are not logged in!');
        return;
      }

      console.log('Token:', token);  // Log the token to ensure it is correct

      // Make the request to the backend API to create a user
      const response = await axios.post(
        `${config.BASE_URL}/users`, 
        { ...newUser },
        {
          headers: {
            Authorization: `Bearer ${token}`,  // Add token to headers for authentication
          },
        }
      );

      // Set success message and reset the form
      setMessage('User added successfully!');
      setNewUser({ name: '', email: '', password: '', role: 'user' }); // Reset form with default 'user' role
    } catch (error) {
      console.error('Error adding user:', error);

      // Handle errors, especially 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        setMessage('Unauthorized. Please log in again.');
      } else {
        setMessage('Error adding user. Please try again.');
      }
    }
  };

  return (
    <div className="flex h-screen">
      {/* Left Pane (Form) */}
      <div className="w-full bg-gray-100 lg:w-1/2 flex items-center justify-center">
        <div className="max-w-md w-full p-6">
          <h1 className="text-3xl font-semibold mb-6 text-[#741017] text-center">Create User</h1>
          <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
            {/* Username */}
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                className="mt-1 p-2 w-full border rounded-md focus:border-[#741017] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#741017] transition-colors duration-300"
                value={newUser.name}
                onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
              />
            </div>
            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 p-2 w-full border rounded-md focus:border-[#741017] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#741017] transition-colors duration-300"
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
              />
            </div>
            {/* Password */}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                className="mt-1 p-2 w-full border rounded-md focus:border-[#741017] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#741017] transition-colors duration-300"
                value={newUser.password}
                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              />
            </div>
            {/* Role Field */}
            <div>
              <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
              <select
                id="role"
                name="role"
                className="mt-1 p-2 w-full border rounded-md focus:border-[#741017] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#741017] transition-colors duration-300"
                value={newUser.role}
                onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
                <option value="superadmin">Superadmin</option>
              </select>
            </div>
            {/* Submit Button */}
            <div>
              <button onClick={handleAddUser} className="w-full bg-[#741017] text-white p-2 rounded-md hover:bg-[#5a0f12] focus:outline-none focus:bg-[#5a0f12] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#741017] transition-colors duration-300">
                Create User
              </button>
            </div>
          </form>
          {/* Display Message */}
          {message && <p className={`mt-4 ${message.includes('successfully') ? 'text-green-600' : 'text-red-600'}`}>{message}</p>}
        </div>
      </div>

      {/* Right Pane (GIF Image) */}
      <div className="hidden lg:flex items-center justify-center flex-1 bg-white text-black">
        <div className="max-w-md text-center">
          <img
            src={myGif} // Use the imported GIF here
            className="mx-auto block h-full w-full object-contain lg:max-w-[90%]"
            alt="login-image"
          />
        </div>
      </div>
    </div>
  );
};

export default AddUser;
