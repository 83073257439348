// Stepper.js
import React from 'react';

const Stepper = ({ currentStep }) => {
  const steps = ['Contact Info', 'Address', 'Company', 'Bank', 'Certificates'];

  return (
    <div className="flex justify-center space-x-4 py-4">
      {steps.map((step, index) => (
        <div key={index} className="flex items-center">
          <div
            className={`rounded-full h-8 w-8 flex items-center justify-center text-white font-bold ${
              currentStep === index + 1 ? 'bg-[#741017]' : 'bg-gray-300'
            }`}
          >
            {index + 1}
          </div>
          <div className={`ml-2 text-sm font-medium ${currentStep === index + 1 ? 'text-[#741017]' : 'text-gray-500'}`}>
            {step}
          </div>
          {index < steps.length - 1 && <div className="mx-2 w-8 h-px bg-gray-300"></div>}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
